<template>
  <div>
    <div class="title  title--medium  title--indent">Описание</div>
    <app-cells position="start">
      <div class="booking-grid">
        <div class="key-value-text">
          <span>Дата визита:</span>
          <span>{{ booking.time_start ? booking.time_start.slice(0, 10) : '-' }}</span>
        </div>
        <div class="key-value-text">
          <span>Когда создано:</span>
          <span>{{ booking.created_at ? booking.created_at : '-' }}</span>
        </div>
        <div class="key-value-text">
          <span>Когда обновлено:</span>
          <span>{{ booking.updated_at ? booking.updated_at : '-' }}</span>
        </div>
        <div class="key-value-text">
          <span>Время визита:</span>
          <span>{{ booking.time_start && booking.time_start.slice(10, 16) }} - {{ booking.time_end && booking.time_end.slice(10, 16) }}</span>
        </div>
        <div class="key-value-text">
          <span>Кем создано:</span>
          <span>{{ booking.created_by ? `${booking.created_by.first_name} ${booking.created_by.last_name}` : 'Система' }}</span>
        </div>
        <div class="key-value-text">
          <span>Кем обновлено:</span>
          <span>{{ booking.updated_by ? `${booking.updated_by.first_name} ${booking.updated_by.last_name}` : 'Система' }}</span>
        </div>
        <div class="key-value-text">
          <span>Просмотрено:</span>
          <span>{{ booking.time_see || '-' }}</span>
        </div>
        <div class="key-value-text">
          <span>Закрыто:</span>
          <span>{{ booking.time_close || '-' }}</span>
        </div>
        <div class="key-value-text"></div>
        <div class="key-value-text">
          <span>Открыто:</span>
          <span>{{ booking.time_open || '-' }}</span>
        </div>
        <div class="key-value-text">
          <span>Отменено:</span>
          <span>{{ booking.time_cancel || '-' }}</span>
        </div>
      </div>
    </app-cells>
    <app-cells position="start">
      <div class="key-value-text  key-value-text--mirror">
        <span>Комментарий гостя:</span>
        <span>{{ booking.comment_guest || '-' }}</span>
      </div>
    </app-cells>
    <app-cells position="start">
      <div class="key-value-text  key-value-text--mirror">
        <span>Комментарий хостес:</span>
        <span>{{ booking.comment_hostes || '-' }}</span>
      </div>
    </app-cells>
    <app-cells position="start">
      <div class="title  title--medium">Столы</div>
      <img v-if="booking.is_fixed_table" src="@/assets/img/lock-big.svg" alt="">
      <img v-if="booking.is_overbooking" src="@/assets/img/overbooking-big.svg" alt="">
    </app-cells>
    <app-cells position="start">
      <div v-if="booking.table" class="booking-card">
        <div class="booking-card__top">
          <div class="booking-card__number">Стол {{ booking.table.number }}</div>
          <div class="booking-card__main">
            <img src="@/assets/img/check-green.svg" alt=""> <span>Основной</span>
          </div>
        </div>
        <div class="booking-card__row">
          <div class="key-value-text">
            <span>Номер стола R_keeeper:</span> <span>{{ booking.table.uuid_rkeeper }}</span>
          </div>
        </div>
        <div class="booking-card__row">
          <div class="key-value-text"><span>Зал:</span> <span>{{ booking.table.hall.name }}</span></div>
        </div>
        <div class="booking-card__row">
          <div class="key-value-text"><span>Кол-во мест:</span>
            <span>
              {{ booking.table.min_seat_number }}
              {{ booking.table.max_seat_number === booking.table.min_seat_number ? '' : `- ${booking.table.max_seat_number}` }}
            </span>
          </div>
        </div>
      </div>
      <template v-if="booking.additional_tables">
        <div v-for="table in booking.additional_tables" :key="table.number" class="booking-card">
          <div class="booking-card__top">
            <div class="booking-card__number">Стол {{ table.number }}</div>
          </div>
          <div class="booking-card__row">
            <div class="key-value-text">
              <span>Номер стола R_keeeper:</span> <span>{{ table.uuid_rkeeper }}</span>
            </div>
          </div>
          <div class="booking-card__row">
            <div class="key-value-text"><span>Зал:</span> <span>{{ table.hall.name }}</span></div>
          </div>
          <div class="booking-card__row">
            <div class="key-value-text"><span>Кол-во мест:</span>
              <span>
              {{ table.min_seat_number }}
              {{ table.max_seat_number === table.min_seat_number ? '' : `- ${table.max_seat_number}` }}
            </span>
            </div>
          </div>
        </div>
      </template>
    </app-cells>
    <template v-if="booking.guest">
      <div class="title title--medium">Гости</div>
      <app-cells position="start">
        <router-link :to="{name: 'guest-detail', params: {id: booking.guest.id}}" class="booking-card">
          <div class="booking-card__top">
            <div class="booking-card__number">{{ booking.guest.phone || '-' }}</div>
            <div class="booking-card__main">
              <img src="@/assets/img/check-green.svg" alt=""> <span>Основной</span>
            </div>
          </div>
          <div class="booking-card__row">
            <div class="key-value-text">
              <span>ID:</span> <span>{{ booking.guest.id }}</span>
            </div>
          </div>
          <div class="booking-card__row">
            <div class="key-value-text"><span>Имя:</span> <span>{{ booking.guest.name || '-' }}</span></div>
          </div>
          <div class="booking-card__row">
            <div class="key-value-text"><span>Email:</span> <span>{{ booking.guest.email || '-' }}</span>
            </div>
          </div>
        </router-link>
        <template v-if="booking.additional_guests">
          <router-link
            :to="{name: 'guest-detail', params: {id: guest.id}}"
            v-for="guest in booking.additional_guests"
            :key="guest.id"
            class="booking-card"
          >
            <div class="booking-card__top">
              <div class="booking-card__number">{{ guest.phone || '-' }}</div>
            </div>
            <div class="booking-card__row">
              <div class="key-value-text">
                <span>ID:</span> <span>{{ guest.id }}</span>
              </div>
            </div>
            <div class="booking-card__row">
              <div class="key-value-text"><span>Имя:</span> <span>{{ guest.name || '-' }}</span></div>
            </div>
            <div class="booking-card__row">
              <div class="key-value-text"><span>Email:</span> <span>{{ guest.email || '-' }}</span>
              </div>
            </div>
          </router-link>
        </template>
      </app-cells>
    </template>

    <template v-if="booking.deposit">
      <template v-if="booking.is_paid">
        <app-cells position="start">
          <div class="title  title--medium">R_keeper</div>
        </app-cells>
        <div class="key-value-text  key-value-text--indent">
          <span>GUID Заказа:</span>
          <span>{{ booking.order_guid || '-' }}</span>
        </div>
        <div class="key-value-text  key-value-text--indent">
          <span>Дата создания заказа:</span>
          <span>{{ booking.time_order || '-' }}</span>
        </div>
        <div class="key-value-text  key-value-text--indent">
          <span>GUID Предоплаты:</span>
          <span>{{ booking.prepay_guid || '-' }}</span>
        </div>
        <div class="key-value-text  key-value-text--indent">
          <span>Дата внесения предоплаты:</span>
          <span>{{ booking.time_prepay || '-' }}</span>
        </div>
      </template>
      <app-cells position="start">
        <div class="title  title--medium">Депозит {{ new Intl.NumberFormat('ru-RU').format(booking.deposit_amount) }} ₽</div>
        <app-status :status="!booking.is_paid ? 'cancel' : 'new'">
          {{ !booking.is_paid ? 'Не оплачен' : 'Оплачен' }}
          <span v-if="booking.pay_due_date">(срок оплаты: {{ booking.pay_due_date }})</span>
        </app-status>
      </app-cells>
      <template v-if="isRefundStatus">
        <div v-if="booking.is_paid && (booking.status.id === 'cancel' || booking.status.id === 'close')" class="key-value-text  key-value-text--indent">
          <span>Требуется возврат?:</span>
          <span>{{ booking.refund_status.name }}</span>
        </div>
        <template v-if="booking.refund_status.id === 'required' || booking.refund_status.id === 'completed'">
          <div class="key-value-text  key-value-text--indent">
            <span>Сумма запроса на возврат:</span>
            <span>{{ booking.refund_request_amount ? `${new Intl.NumberFormat('ru-RU').format(booking.refund_request_amount)} ₽` : '-' }} <template v-if="booking.time_refund_request">({{ booking.time_refund_request }})</template></span>
          </div>
          <div class="key-value-text  key-value-text--indent">
            <span>Возвращено:</span>
            <span>{{ new Intl.NumberFormat('ru-RU').format(booking.refund_amount) }} ₽</span>
          </div>
        </template>
      </template>
      <div v-if="booking.balance !== booking.deposit_amount && booking.is_paid" class="key-value-text  key-value-text--indent">
        <span>Итоговый баланс:</span>
        <span>{{ new Intl.NumberFormat('ru-RU').format(booking.balance) }} ₽</span>
      </div>
      <app-cells position="start">
        <div class="title title--medium">Транзакции</div>
      </app-cells>
      <v-client-table
        :data="booking.transactions"
        :columns="table.columns"
        :options="table.options"
        ref="tableDefault"
        class="table-default"
      >
        <div slot="amount" slot-scope="props">
          {{ new Intl.NumberFormat('ru-RU').format(props.row.amount) }}
        </div>
        <div slot="type" slot-scope="props">
          {{ props.row.type === 'Pay' ? 'Оплата' : props.row.type === 'Check' ? 'Попытка оплаты' : 'Возврат' }}
        </div>
        <div slot="total_fee" slot-scope="props">
          {{ new Intl.NumberFormat('ru-RU').format(props.row.total_fee) }}
        </div>
      </v-client-table>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BookingDetail',
  props: {
    booking: {
      type: Object,
    },
  },
  data() {
    return {
      timeValue: '',
      show_sidebar: false,
      deposit_response: false,
      deposit_error: false,
      rk_response: false,
      rk_error: false,
      table: {
        items: [],
        columns: ['id', 'date', 'type', 'amount', 'payment_currency', 'total_fee', 'response_code'],
        options: {
          headings: {
            id: 'ID',
            date: 'Дата',
            type: 'Тип операции',
            amount: 'Сумма',
            payment_currency: 'Тип валюты',
            total_fee: 'Комиссия',
            response_code: 'Код ответа'
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            noResults: 'Нет подходящих записей',
          },
          rowClassCallback: () => 'table-default__row',
          resizableColumns: false
        },
      },
    }
  },
  created() {
    document.title = `Бронь - ID: ${ this.$route.params.id } | BMS - ${process.env.VUE_APP_RESTARAUNT_TITLE}`
  },
  computed: {
    isRefundStatus() {
      return this.booking.refund_status && Object.keys(this.booking.refund_status).length
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.booking-grid
  display: inline-grid
  grid-template-columns: repeat(3, 1fr)
  gap: 15px 50px

.booking-card
  max-width: 418px
  width: 100%
  padding: 20px 16px
  border: 1px solid #eceff4
  border-radius: 10px
  background-color: $color-bg-third
  text-decoration: none
  color: $color-text-base

  &[href]
    transition: background-color 0.3s
    &:active
      background-color: #d6e5fc

    @media (hover: hover)
      &:hover,
      &:focus
        background-color: #d6e5fc

.booking-card__top
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 12px

.booking-card__number
  color: $color-theme

.booking-card__main
  display: flex
  align-items: center
  gap: 0 6px
  color: #38bf4e

.booking-card__row
  margin-bottom: 8px

.xml-code
  margin-bottom: 20px
  padding: 10px 15px
  background-color: $color-text-base

.fade-enter-active, .fade-leave-active
  transition: opacity 0.2s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
